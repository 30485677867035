@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Inter', sans-serif;
  @apply bg-main antialiased text-body;
}

#__next {
  height: 100%;
}

.forceStroke > path,
g {
  stroke: currentColor;
}
